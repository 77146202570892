import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "プラットフォームをまたぎブレない仕様を実現するための、ネイティブアプリ開発施策",
  "date": "2018-03-27T09:06:21.000Z",
  "slug": "entry/2018/03/27/180621",
  "tags": ["medley"],
  "hero": "./2018_03_27.png",
  "heroAlt": "DSL"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の高井です。オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」のアプリ開発を主に担当しています。`}</p>
    <p>{`CLINICS では Web に加えて、iOS 版と Android 版の各プラットフォームの仕様変更や機能追加などをほぼ同時に開発しているのですが、担当する人数が増えたりすることで、仕様に差が出たり、その結果手戻りが起きるということも増え始めていました。`}</p>
    <p>{`そうした課題を解決するために実践した様々な施策の中から、特に有効だった 3 つの改善策について、今日はご紹介します。`}</p>
    <h1>{`背景`}</h1>
    <p>{`CLINICS の開発チームでは 5 人ほどのエンジニアがタスク単位で全てのプラットフォームを実装したり、大きいタスクの場合はプラットフォーム毎に別の開発者が担当する形で開発しています。`}</p>
    <p>{`そのような形で機能追加や不具合対応の開発を進める中で、以下のような課題がありました。`}</p>
    <ul>
      <li parentName="ul">{`プラットフォーム間で仕様やデザインが違う`}</li>
      <li parentName="ul">{`リリース直前に仕様の違いが見つかり、手戻りが発生する`}</li>
      <li parentName="ul">{`各プラットフォームに対する習熟度にバラつきがあるため、開発者によって実装方法が違う`}</li>
    </ul>
    <p>{`特にプラットフォーム毎に開発者がほぼ固定されてしまっていた時期には、コードレビューはしていても微妙な違いに気づかなかったり、同じ UI にするのに実装コストが高くてあきらめたり、ということが起こりがちでした。`}<strong parentName="p">{` プラットフォーム間で仕様やデザインが違うとユーザ体験の質がプラットフォームによってバラついてしまいますし、デザインや企画の作業も増えてしまいます`}</strong>{` 。これらに加えて、エンジニアの人数が増えたり、デザイナーやカスタマーサポートなどエンジニア以外のメンバーとのコミュニケーションも増えたりしてきたこともあって、開発スピードも段々と遅くなってきていました。`}</p>
    <p>{`そのような状況を改善するために、チーム内で継続的に実装方法や開発フローを見直し、改善策を実施してきました。`}</p>
    <p>{`今回は以下の 3 つの改善策をご紹介します。具体的な実装については、主に iOS で使用しているコードを引用してご紹介します。（コードの一部を抜粋しているので、そのままでは使用することはできません。あくまでも参考コードとして読んでください。）`}</p>
    <ul>
      <li parentName="ul">{`DLS（デザイン言語システム）の導入`}</li>
      <li parentName="ul">{`アプリエラーの共通化`}</li>
      <li parentName="ul">{`コードレビューの手順改善`}</li>
    </ul>
    <h1>{`改善策 1 DLS（デザイン言語システム）の導入`}</h1>
    <p>{`まずは DLS（デザイン言語システム）の導入についてです。DLS とは以前、本ブログでもデザイナーの前田がご紹介させていただきましたが（`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/03/160000"
      }}>{`デザイン言語システムを入れたらコミュニケーションコストがぐっと下がった話〜メドレー TechLunch〜`}</a>{`）、`}<strong parentName="p">{` UI に一貫性をもたせるため、配色やレイアウト、タイポグラフィやマージンなどのルール `}</strong>{`を策定し、チーム全体で継続的に運用していくための仕組みです。策定したルールを組み込んだ各コンポーネントのデザインを元に、Web / iOS / Android の各プラットフォームで UI を実装して開発時に再利用できるようにしています。デザイン自体は下記のような形で Sketch ファイルで管理しています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180327/20180327164233.png",
      "alt": "20180327164233.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180327/20180327164243.png",
      "alt": "20180327164243.png"
    }}></img>
    <p>{`iOS については各コンポーネントをカスタムビュークラスとして実装し、再利用できるようにしました。DLS 導入以前はプラットフォーム毎に違った UI やルールで開発していたので、実装段階で担当する開発者毎の認識によって品質や仕様に差が出ている状態でした。DLS 導入によってそのような差が出にくくなり、一定の品質を保つことができるようになりました。
また、`}<strong parentName="p">{` UI の微調整などが減って、機能ロジックに重点を置いた開発に専念できるようになり、さらにデザイナーとの認識合わせが最小限になったことにより開発効率も上がった `}</strong>{`と感じています。UI の基盤をつくったことで新しく画面を開発する場合でもコンポーネントを組み合わせ、エンジニアだけで実装が完了することも多くなり、その分デザイナーは次の施策やプロジェクトに専念できるようになりました。`}</p>
    <p>{`実装についてですが、各コンポーネント毎に xib ファイルで UI パーツを作成し、それをクラスファイルで読み込んでカスタムビュークラスの見た目として使っています。カスタムビューは再利用しやすく、利用時にバラツキが出にくいように以下の点を満たすように実装しました。`}</p>
    <ul>
      <li parentName="ul">{`Interface Builder/コードのどちらからでも初期化できる`}</li>
      <li parentName="ul">{`ビルドする前に Storyboard 上で UI パーツのデザインを確認できるように IBDesignable と IBInspectable を指定する`}</li>
      <li parentName="ul">{`カスタムビューの中で UI 要素のマージンや高さを指定する`}</li>
    </ul>
    <p>{`例えば、セレクトフォームコンポーネントのカスタムビューは以下のような実装になっています。`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`xib ファイル`}</p>
        <img parentName="li" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180327/20180327164320.png",
          "alt": "20180327164320.png"
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`クラスファイル`}</p>
      </li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "swift"
    }}><pre parentName="div" {...{
        "className": "language-swift"
      }}><code parentName="pre" {...{
          "className": "language-swift"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UIKit`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`protocol`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicsFormSelectDelegate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`didClickFormSelect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sender`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicsFormSelect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token attribute atrule"
          }}>{`@IBDesignable`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicsFormSelect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UIView`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token attribute atrule"
          }}>{`@IBOutlet`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`weak`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` selectView`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SelectView`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
    `}<span parentName="code" {...{
            "className": "token attribute atrule"
          }}>{`@IBInspectable`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` labelText`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"Form-parts"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`didSet`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            selectView`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`labelText `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` labelText
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`weak`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` delegate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicsFormSelectDelegate`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// コードから初期化する場合に呼ばれる`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`override`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`init`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`CGRect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`init`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`commonInit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Interface Builder から初期化する場合に呼ばれる`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`required`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`init`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`coder aDecoder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NSCoder`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`init`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`coder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` aDecoder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`commonInit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`commonInit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// xib ファイルの読み込み`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` bundle `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Bundle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`of`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` view `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UINib`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`nibName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"ClinicsFormSelect"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bundle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`instantiate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`withOwner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token nil constant"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`first `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UIView`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`addSubview`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        backgroundColor `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`clear
        view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundColor `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`clear

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 読み込んだ View のサイズがカスタムクラス（ClinicsFormSelect）と同じサイズになるように Constraint を設定する`}</span>{`
        view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`translatesAutoresizingMaskIntoConstraints `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` bindings `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"view"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`addConstraints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`NSLayoutConstraint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`constraints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`withVisualFormat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"H:|[view]|"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                                      options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`NSLayoutFormatOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`rawValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                                      metrics`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token nil constant"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                                      views`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bindings`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`addConstraints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`NSLayoutConstraint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`constraints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`withVisualFormat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"V:|[view]|"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                                      options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`NSLayoutFormatOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`rawValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                                      metrics`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token nil constant"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                                      views`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bindings`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// xib ファイルの中に配置した UI 要素へのアクションのハンドリング`}</span>{`
    `}<span parentName="code" {...{
            "className": "token attribute atrule"
          }}>{`@IBAction`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`didTap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token omit keyword"
          }}>{`_`}</span>{` sender`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UITapGestureRecognizer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        delegate`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`didClickFormSelect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sender`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`CLINICS では主に Storyboard を使って UI を実装しているので、使用するときは Storyboard に UIView を置き、コンポーネントのクラス名を指定して使います。テキストなどのプロパティを設定し、Constraint を指定して配置すれば完了です。ユーザによるアクションのハンドリングや動的にプロパティを切り替える必要がある場合は、呼び出し側で処理を追加します。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180327/20180327172653.png",
      "alt": "20180327172653.png"
    }}></img>
    <p>{`最終的にビルドすると以下のように表示されます。（表示されている内容は開発中に作成した仮のデータで実際のものとは異なります。）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180327/20180327172923.png",
      "alt": "20180327172923.png"
    }}></img>
    <h1>{`改善策 2 アプリエラーの共通化`}</h1>
    <p>{`以前は業務的に重要な処理のエラー以外はプラットフォーム毎で表示するエラーメッセージが異なっていたり、エラーハンドリング時に違った挙動をしていることがありました。その結果、ユーザ体験が一貫したものになっていないというだけでなく、お問い合わせがあってもカスタマーサポートが一次回答しにくかったり、伝えられた内容が曖昧なため開発者が調査するのに時間がかかったりすることがありました。`}</p>
    <p>{`そこで、改めてフロント側で発生するエラーの定義を共通化し、エラーメッセージやエラーハンドリング時の処理も統一しました。`}<strong parentName="p">{` 問い合わせの効率化のために共通のエラーコードも決めて、エラー発生時に表示されるアラートに追加し、それらのエラー定義はドキュメントで一覧化して、カスタマーサポートにも共有 `}</strong>{`するようにしました。`}</p>
    <p>{`また、エラーハンドリング時にクラッシュレポートのログに記録する内容や送信するタイミングを統一して、開発者全員が理解しやすいようにしました。エラーコードの表示については、改善を検討していた時期にちょうど参加していた iOSDC Japan 2017 で、同じような課題に対する知見を`}<a parentName="p" {...{
        "href": "https://iosdc.jp/2017/node/1422"
      }}>{`発表`}</a>{`されていたのを見て、早速取り入れました。最近ではユーザからの問い合わせにもエラーコードが使われることがあり、実際にコミュニケーションコストを低下させることができているように思います。`}</p>
    <p>{`エラーのフィードバックは細かいところではありますが、ユーザのアクションを継続させるために重要な要素のひとつです。CLINICS はユーザ属性が老若男女問わず幅広いので特に気を配って改善を行ってきました。
実装についてですが、iOS では以下のように定義しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "swift"
    }}><pre parentName="div" {...{
        "className": "language-swift"
      }}><code parentName="pre" {...{
          "className": "language-swift"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`enum`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ApplicationError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Error`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`commonRequestError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` createReservationCardError
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` createReservationScheduleIsFullError
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`~`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` errorCode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`commonRequestError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`viewId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\\(`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}>{`viewId`}</span><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`-0000"`}</span></span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`createReservationCardError`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"40-0001"`}</span></span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`createReservationScheduleIsFullError`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"40-0002"`}</span></span>{`

       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`~`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`commonRequestError`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"接続エラー"`}</span></span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`createReservationCardError`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"決済失敗エラー"`}</span></span>{`

        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`~`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`commonRequestError`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"データを正しく表示出来ない可能性があります。\\n 通信状況をお確かめいただくか、しばらく経ってから再度起動してください。"`}</span></span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`createReservationCardError`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"ご登録されているクレジットカードの決済中にエラーが発生しました。\\n おそれいりますが、もう一度最初から操作ください。"`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`~`}</span>{`
`}</code></pre></div>
    <p>{`Android でも同様に enum で定義しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "kotlin"
    }}><pre parentName="div" {...{
        "className": "language-kotlin"
      }}><code parentName="pre" {...{
          "className": "language-kotlin"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`enum`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ApplicationError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` code`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` String`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` String`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` description`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` String`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`CommonRequestError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"0000"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"接続エラー"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"データを正しく表示出来ない可能性があります。\\n 通信状況をお確かめいただくか、しばらく経ってから再度起動してください。"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`CreateReservationCardError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"40-0001"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"決済失敗エラー"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"ご登録されているクレジットカードの決済中にエラーが発生しました。\\n おそれいりますが、もう一度最初から操作ください。"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`CreateReservationScheduleIsFullError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"40-0002"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"スケジュール空きなしエラー"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"選択された予約日時のスケジュールに空きがありませんでした。\\n おそれいりますが、別の予約日時をご選択のうえ、もう一度最初から操作ください。"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    ~
`}</code></pre></div>
    <h1>{`改善策 3 コードレビューの手順改善`}</h1>
    <p>{`リリース当初から実装者以外のメンバーによるレビューは適宜行なっていましたが、レビューの段階でデグレや仕様の違いを見逃してしまうことがあったので、レビュー体制の強化とメンバーのソース理解の向上を図るために、以下のようにルールを設定しました。`}</p>
    <ul>
      <li parentName="ul">{`セルフマージはしない`}</li>
      <li parentName="ul">{`PR に対して 2 人以上でレビューする`}</li>
      <li parentName="ul">{`ビューの変更があった場合には画面キャプチャを貼る`}</li>
    </ul>
    <p>{`それらを守りやすく、より効率的にするために`}<a parentName="p" {...{
        "href": "https://danger.systems/ruby/"
      }}>{`Danger`}</a>{`も導入しました。
導入手順は`}<a parentName="p" {...{
        "href": "https://danger.systems/guides/getting_started.html"
      }}>{`こちら`}</a>{`にまとめられているほか、検索すればけっこう出てくるので省略します。弊社では iOS の CI は Bitrise を使用しているので Bitrise 上で実行して GitHub の PR に反映させています。`}</p>
    <p>{`Danger では、以下の項目をチェックしています。上記のルールを反映しているのに加えて、PR の向き先と SwiftLint の実行結果もチェックしています。CLINICS の iOS アプリでは GitFlow を導入しているため、release ブランチと hot-fix ブランチ以外からの PR の向き先が develop ブランチになっていない場合には警告を出すようにしています。`}</p>
    <ul>
      <li parentName="ul">{`レビュアーの人数が 2 人以上になっているか`}</li>
      <li parentName="ul">{`ビューの変更（xib、storyboard を触ったかどうかのみ確認）があった場合に画面キャプチャを貼っているかどうか`}</li>
      <li parentName="ul">{`PR が develop に向けて作成されているか`}</li>
      <li parentName="ul">{`SwiftLint のチェックを通っているか`}</li>
    </ul>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180327/20180327164527.png",
      "alt": "20180327164527.png"
    }}></img>
    <p>{`弊社が iOS 開発で利用している Danger ファイルは以下の通りとなっています。導入する際のご参考にしてください。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# for only difference`}</span>{`
github`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`dismiss_out_of_range_messages

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# reviewers`}</span>{`
warn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"レビュアーは 2 人以上指定してください"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` github`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`github`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pr_json`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"requested_reviewers"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# view changes`}</span>{`
view_extensions `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`".xib"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`".storyboard"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
has_view_changes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` git`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`modified_files`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`file`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` view_extensions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`ext`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`end_with`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` ext `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
has_view_added `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` git`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`added_files`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`file`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` view_extensions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`ext`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`end_with`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` ext `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
pr_has_screenshot `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` github`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pr_body `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=~`}</span>{` `}<span parentName="code" {...{
            "className": "token regex-literal"
          }}><span parentName="span" {...{
              "className": "token regex"
            }}>{`/https?:\\/\\/\\S*\\.(png|jpg|jpeg|gif){1}/`}</span></span>{`
warn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"見た目に変更がある場合は画面キャプチャを貼ってください"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`has_view_changes `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`or`}</span>{` has_view_added`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`pr_has_screenshot

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# base branch`}</span>{`
is_to_master `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` github`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`branch_for_base `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'master'`}</span></span>{`
is_to_develop `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` github`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`branch_for_base `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'develop'`}</span></span>{`
is_from_releases `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`github`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`branch_for_head`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`match`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token regex-literal"
          }}><span parentName="span" {...{
              "className": "token regex"
            }}>{`/releases\\/[0-9]+\\.[0-9]+\\.[0-9]/`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
warn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'PR は develop に向けてください'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`is_to_develop `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`is_from_releases `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` is_to_master`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# swiftLint`}</span>{`
swiftlint`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`lint_files inline_mode`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span></code></pre></div>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180327/20180327164549.png",
      "alt": "20180327164549.png"
    }}></img>
    <h1>{`まとめ`}</h1>
    <p>{`CLINICS におけるアプリ開発の品質と効率性を向上するための取り組みをご紹介しました。これらの取り組みによって`}<strong parentName="p">{`プラットフォーム毎のデザインや機能のブレが少なくなり、認識ずれによる手戻りなどが少なくなったことで開発効率が上がった`}</strong>{`と感じます。プラットフォーム毎の違いを少なくして、より多くのメンバーがコードに手を入れやすい状態にすることで実装やコードレビューの質も向上しているように思います。`}</p>
    <p>{`React Native などを利用して、コードそのものを共通化する方法もあるとは思いますが、プラットフォーム毎に別のコードで開発する場合でも、仕様や実装のルールを工夫することでより効率的に開発できるのではないでしょうか。`}</p>
    <p>{`CLINICS チームでは他にも実装や開発プロセス、プロダクト運用について日々改善を行なっています。今後も、こうした取り組みを積極的に実践し、KPT 形式で振り返って、また次のアクションにつなげることで、多くの方に愛されるプロダクトを育てていきたいと思っています。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、エンジニアやデザイナーを募集しています。ご興味のある方は、こちらからどうぞ！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      